"use client";

import { Label, Pie, PieChart } from "recharts";

import { CardContent } from "../../../../third-party/shadcn/card";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../third-party/shadcn/chart";
import { IntegrationItem } from "../../../../models/integration-item.model";
import { useEffect, useState } from "react";

interface Props {
  data: IntegrationItem[];
}

const chartConfig = {
  attempts: {
    label: "Attempts",
  },
  Success: {
    label: "Success",
    color: "#000000",
  },
  Failed: {
    label: "Failed",
    color: "#000000",
  },
} satisfies ChartConfig;

export const HistorySuccessRatePieChart: React.FC<Props> = (props: Props) => {
  const { data } = props;
  console.log(data);
  const [chartProps, setChartProps] = useState<
    { result: string; attempts: number; fill: string }[]
  >([]);

  const init = () => {
    const historySuccessRateData = [
      {
        result: "Success",
        attempts: data.filter((x) => x.conclusion === "success").length,
        fill: "#007B7F",
      },
      {
        result: "Failed",
        attempts: data.filter((x) => x.conclusion !== "success").length,
        fill: "#D76D3D",
      },
    ];
    setChartProps(historySuccessRateData);
  };
  useEffect(() => {
    init();
  }, [data]);

  return !chartProps ? (
    <>Loading...</>
  ) : (
    <>
      <CardContent className="flex-1 pb-0 p-2 items-center">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Pie
              data={chartProps}
              dataKey="attempts"
              nameKey="result"
              innerRadius={55}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) - 5}
                          className="fill-foreground text-2xl font-bold"
                        >
                          {
                            data.filter((x) => x.conclusion === "success")
                              .length
                          }
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 16}
                          className="fill-muted-foreground w-[50px]"
                        >
                          Success
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <PieChart accessibilityLayer />
          </PieChart>
        </ChartContainer>
      </CardContent>
    </>
  );
};
