"use client";

import { Label, PolarRadiusAxis, RadialBar, RadialBarChart } from "recharts";

import { CardContent } from "../../../../third-party/shadcn/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../third-party/shadcn/chart";
import { useEffect, useState } from "react";
import { formatSeconds } from "../../../../utils/number.util";

interface Props {
  data: { averageDuration: number; totalDuration: number };
}

const chartConfig = {} satisfies ChartConfig;

export const AverageDurationRadialChart: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const [chartProps, setChartProps] = useState<{ duration: number }[]>([]);

  const init = () => {
    const averageDurationRadialChartData = [{ duration: data.averageDuration }];
    setChartProps(averageDurationRadialChartData);
  };
  useEffect(() => {
    init();
  }, [data]);

  return !chartProps ? (
    <>Loading...</>
  ) : (
    <>
      <CardContent className="flex flex-1 items-center pb-0 w-[250px] ">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square w-full max-w-[250px]"
        >
          <RadialBarChart
            data={chartProps}
            endAngle={180}
            innerRadius={80}
            outerRadius={145}
          >
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) - 16}
                          className="fill-foreground text-2xl font-bold"
                        >
                          {formatSeconds(data.averageDuration)}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 4}
                          className="fill-muted-foreground"
                        >
                          Average duration
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </PolarRadiusAxis>
            <RadialBar
              dataKey="duration"
              //   stackId="a"
              cornerRadius={5}
              fill="#D76D3D"
              className="stroke-transparent stroke-2"
            />
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
    </>
  );
};
