import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spinner,
  Image,
  Text,
  Tooltip,
  Divider,
  useToast,
  background,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { formatDate } from "../../utils/date.util";
import { IntegrationService } from "../../service/integration.service";
import { StatusTag } from "../../components/shared/StatusTag";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/shared/Loading";
import BasePageTitleContainer from "../../components/form/BasePageTitleContainer";
import { FlexIconRow } from "../../components/shared/FlexIconRow";
import {
  CheckIcon,
  ClockIcon,
  CrossIcon,
  InfoIcon,
} from "../../components/shared/icons/icons";
import { formatSeconds } from "../../utils/number.util";
import { store } from "../../store";
import { successToast } from "../../constants/toast.constants";
import { HistorySuccessRatePieChart } from "../../components/dashboard/charts/v2/HistoryPieChart";
import { TotalDurationAreaChart } from "../../components/dashboard/charts/v2/TotalDurationAreaChart";
import { AverageDurationRadialChart } from "../../components/dashboard/charts/v2/AverageDurationRadialChart";
import { TotalDurationRadialChart } from "../../components/dashboard/charts/v2/TotalDurationRadialChart";

const RUN_DEVIATION_THRESHOLD = 0.5;

const baseContainerStyles = {
  mx: "auto",
  padding: "0",
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minWidth: "95%",
  minH: { base: "calc(100vh - 80px - 24px)" },
  paddingBottom: "50px",
};

const integrationService = new IntegrationService();

export const History: React.FC<any> = (props: any) => {
  const { id } = useParams();
  const toast = useToast();

  const [runs, setRuns] = useState([] as any[]);
  const [loading, setLoading] = useState(true);
  const [metric, setMetrics] = useState({
    totalDuration: 0,
    averageDuration: 0,
  } as any);

  const [focussedRun, setFocussedRun] = useState({
    id: null,
    status: null,
    logs: [],
  });

  const { workflows } = store.getState().sidebar;

  const init = async () => {
    setLoading(true);
    await setWorkflowData().finally(() => {
      setLoading(false);
    });
  };

  const handleToggle = async (runId: string) => {
    if (focussedRun.id === runId) {
      setFocussedRun({ id: null, status: null, logs: [] });
      return;
    }

    setFocussedRun((prev) => ({
      ...prev,
      id: runId === prev.id ? null : runId,
    }));

    await setRunHistory(runId);
  };

  const setWorkflowData = async () => {
    await integrationService.getWorkflowRunsHistory(id).then((history) => {
      setRuns(history);
      setMetrics({
        totalDuration: history?.reduce((acc, x) => acc + x.duration, 0),
        averageDuration: Math.round(
          history?.reduce((acc, x) => acc + x.duration, 0) /
            (history.length || 1)
        ),
      });
    });
    console.log(runs);
    console.log(metric);
  };

  const setRunHistory = async (runId: string) => {
    const run = await integrationService.getWorkflowRunLog({
      integrationName: "RWG-Daily",
      workflowId: id,
      workflowRunId: runId,
    });

    const uniqueMessages = run?.logs?.reduce((acc, x) => {
      if (!acc.find((y) => y.Message.S === x.Message.S)) {
        acc.push(x);
      }
      return acc;
    }, []);

    setFocussedRun({ id: runId, status: run?.status, logs: uniqueMessages });

    if (run?.status === "Completed") {
      await setWorkflowData();
    }

    toast(successToast("Logs refreshed."));
  };

  const runDurationAcceptable = (duration: number) => {
    const deviation = Math.abs(duration - metric.averageDuration);
    const deviationPercentage = deviation / metric.averageDuration;

    // console.log(
    //   deviationPercentage,
    //   duration,
    //   metric.averageDuration,
    //   deviation
    // );
    return deviationPercentage < RUN_DEVIATION_THRESHOLD;
  };

  useEffect(() => {
    init();
  }, [id]);

  return (
    <Layout>
      {/* <Loading loading={loading} /> */}
      <BasePageTitleContainer
        style={{
          background: "transparent",
          maxWidth: "95%",
          padding: "0px",
        }}
      >
        <div className="my-5">
          <div className="text-4xl font-bold">
            {runs[0]?.friendlyName || "Workflow"} History
          </div>
        </div>
      </BasePageTitleContainer>
      <div className="flex flex-row w-[95%] h-[360px] mx-auto gap-6">
        {/* First Card */}
        <BasePageTitleContainer mt="0px" padding="24px" alignItems="">
          <div>
            <div className="text-center font-semibold">
              Duration for the 30 most recent run attempts
            </div>
          </div>
          <div className="">
            <TotalDurationAreaChart data={runs} />
          </div>
        </BasePageTitleContainer>
        {/* Second Card */}
        <BasePageTitleContainer mt="0px" padding="24px">
          <div>
            <div className="text-center font-semibold">
              {workflows?.find((x) => +x.id === +id)?.description}
            </div>
            <div className="text-center text-sm w-[70%] mx-auto">
              Displaying the success rate of the 30 most recent run attempts
            </div>
          </div>
          <div className="flex flex-row items-center">
            <HistorySuccessRatePieChart data={runs} />
            <Flex flexDir="column" gap="4px" width="45%">
              <Flex flexDir="row" gap="4px" alignItems="center">
                <div className="bg-[#007B7F] h-3 w-4"></div>
                <div className="font-normal text-sm mr-[4px] w-[80px]">
                  Success
                </div>
                <div className="font-medium text-sm ">
                  {runs?.filter((x) => x.conclusion === "success").length}
                </div>
              </Flex>
              <Flex flexDir="row" gap="4px" alignItems="center">
                <div className="bg-[#D76D3D] h-3 w-4"></div>
                <div className="font-normal text-sm mr-[4px] w-[80px]">
                  Failed
                </div>
                <div className="font-medium text-sm ">
                  {runs?.filter((x) => x.conclusion !== "success").length}
                </div>
              </Flex>
              <Flex flexDir="row" gap="4px" alignItems="start">
                <div className="bg-[#ffcc00] h-3 w-4 mt-1"></div>
                <div className="font-normal text-sm mr-[4px] w-[80px] leading-snug">
                  Requiring attention
                </div>
                <div className="font-medium text-sm ">
                  {
                    runs?.filter((x) => !runDurationAcceptable(x.duration))
                      .length
                  }
                </div>
              </Flex>
            </Flex>
          </div>
        </BasePageTitleContainer>
        {/* Third Card */}
        <div className="flex flex-col">
          <BasePageTitleContainer height="168px" mt="0px">
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <div>
                <p className="font-semibold">Total duration</p>
              </div>
              <TotalDurationRadialChart data={metric} />
            </Flex>
          </BasePageTitleContainer>
          <BasePageTitleContainer height="168px">
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <Text fontWeight="600">Average duration</Text>
              <AverageDurationRadialChart data={metric} />
            </Flex>
          </BasePageTitleContainer>
        </div>
      </div>
      <BaseContainer styles={baseContainerStyles}>
        <Accordion allowToggle>
          {runs?.map((run: any, i: number) => (
            <AccordionItem key={run.id}>
              <AccordionButton key={i} onClick={() => handleToggle(run.id)}>
                <Box py="3px" as="span" flex="1" textAlign="left">
                  <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    w="80%"
                  >
                    <FlexIconRow gap="18px">
                      {!!run.conclusion ? (
                        <>
                          {!runDurationAcceptable(run.duration) && <>⚠️</>}
                          <StatusTag
                            type={run.conclusion}
                            style={{ width: "120px" }}
                          >
                            {run.conclusion}
                          </StatusTag>
                          <Box>{formatDate(run.lastRunDate)}</Box>
                          <Box></Box>
                        </>
                      ) : (
                        <>
                          <Spinner />
                          <Box>
                            {focussedRun?.status === "Completed" &&
                            focussedRun.id === run.id ? (
                              "✅ Integration has completed. We are cleaning up now, but your Anaplan models should be updated."
                            ) : (
                              <Box
                                cursor="pointer"
                                _hover={{ textDecor: "underline" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRunHistory(run.id);
                                }}
                              >
                                Currently runnning. Cick here to refresh.
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                    </FlexIconRow>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <br />
                {run.conclusion && (
                  <>
                    <Flex flexDirection="column" gap="16px">
                      <Text>
                        Completed with status {run.conclusion} in {run.attempt}{" "}
                        attempt(s).
                      </Text>
                      <Text>Duration: {formatSeconds(run.duration)}</Text>
                      {!runDurationAcceptable(run.duration) && (
                        <Box fontSize="14px" color="red">
                          We have flagged this run as Requiring Attention as the
                          Run Duration deviates greatly from the average.
                        </Box>
                      )}
                    </Flex>
                  </>
                )}

                <Divider my="12px" />

                {focussedRun.id === run.id && !!focussedRun?.logs?.length ? (
                  <Flex
                    flexDir="column"
                    p="20px"
                    bg="black"
                    color="white"
                    borderRadius="8px"
                  >
                    {focussedRun.logs.map((log: any, i: number) => (
                      <Flex key={i} flexDir="row" gap="12px">
                        <Text>{log.Message?.S ?? "--"}</Text>
                      </Flex>
                    ))}
                    {focussedRun.status === "Completed" ? (
                      <Flex flexDir="row" justifyContent="center">
                        - Integration has completed -
                      </Flex>
                    ) : (
                      <Flex
                        flexDir="row"
                        justifyContent="center"
                        color="yellow"
                        cursor="pointer"
                        _hover={{ textDecor: "underline" }}
                        onClick={() => setRunHistory(run.id)}
                      >
                        - Load More -
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <>
                    {!!run.conclusion ? (
                      "No logs are available for this integration run."
                    ) : (
                      <Box
                        cursor="pointer"
                        _hover={{ textDecor: "underline" }}
                        onClick={() => setRunHistory(run.id)}
                      >
                        Currently runnning. Cick here to refresh.
                      </Box>
                    )}
                  </>
                )}

                <Box></Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
        <Box fontSize="14px" position="absolute" p="16px" bottom="0">
          Displaying top 30 results.
        </Box>
      </BaseContainer>
    </Layout>
  );
};
