"use client";

import { Area, AreaChart, CartesianGrid, Label, XAxis, YAxis } from "recharts";

import { CardContent } from "../../../../third-party/shadcn/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../third-party/shadcn/chart";
import { IntegrationItem } from "@/src/models/integration-item.model";
import { useEffect, useState } from "react";

interface Props {
  data: IntegrationItem[];
}

const chartConfig = {} satisfies ChartConfig;

export const TotalDurationAreaChart: React.FC<Props> = (props: Props) => {
  const { data } = props;
  console.log(data);
  const [chartProps, setChartProps] = useState<
    { duration: number; recentAttempt: number; key: string }[]
  >([]);

  const init = () => {
    const totalDurationAreaChartData = data.map((item, index) => ({
      duration: item.duration,
      recentAttempt: index + 1,
      key: item.id,
    }));
    setChartProps(totalDurationAreaChartData);
  };
  useEffect(() => {
    init();
  }, [data]);
  return !chartProps ? (
    <>Loading...</>
  ) : (
    <>
      <CardContent className=" pb-0 p-2 items-center">
        <ChartContainer config={chartConfig}>
          <AreaChart accessibilityLayer data={chartProps}>
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              dataKey="recentAttempt"
              tickLine={false}
              axisLine={false}
              tickMargin={4}
              height={40}
            >
              <Label
                value="Recent run attempts"
                position={{ x: 170, y: 40 }}
                style={{ textAnchor: "middle", fill: "#6e7280" }}
              />
            </XAxis>
            <YAxis
              dataKey="duration"
              tickLine={false}
              axisLine={false}
              width={50}
              tickCount={5}
            >
              <Label
                value="Duration (Seconds)"
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: "middle", fill: "#6E7280" }}
              />
            </YAxis>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            <Area
              dataKey="duration"
              type="natural"
              fill="#007B7F"
              fillOpacity={0.4}
              stroke="#007B7F"
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </>
  );
};
