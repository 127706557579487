import { IntegrationItem } from "../../../../models/integration-item.model";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { useEffect, useState } from "react";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../third-party/shadcn/chart";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../third-party/shadcn/card";
import React from "react";
import { Card } from "@chakra-ui/react";

interface Props {
  data: IntegrationItem[];
}

const chartConfig = {
  desktop: {
    label: "Run Frequency",
    color: "#FFFFFF",
  },
} satisfies ChartConfig;

const CustomXAxisTick = ({ x, y, payload }) => {
  const lines = payload.value.split(" ");
  return (
    <g transform={`translate(${x},${y + 5})`}>
      {lines.map((line, index) => (
        <text key={index} x={0} y={index * 13} textAnchor="middle" fill="#666">
          {line}
        </text>
      ))}
    </g>
  );
};

export const RunFrequency: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [chartProps, setChartProps] = useState<any[]>();
  const [guid, setGuid] = useState(0);

  const init = () => {
    const result = data.reduce((acc, { friendlyName, attempt }) => {
      const existingIndex = acc.findIndex((item) => item.runs === friendlyName);
      if (existingIndex !== -1) {
        acc[existingIndex].frequency += attempt;
      } else {
        acc.push({ runs: friendlyName, frequency: attempt });
      }
      return acc;
    }, [] as any[]);

    setChartProps(result);
    setGuid(guid + 1);
  };

  useEffect(() => {
    init();
  }, [data]);

  return (
    <>
      <CardHeader className="flex flex-col items-stretch p-0">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle>Run Frequency</CardTitle>
          <CardDescription>
            01 {new Date().toLocaleString("default", { month: "long" })} -
            current
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[350px] w-full"
        >
          <BarChart accessibilityLayer data={chartProps}>
            <XAxis
              dataKey="runs"
              tickLine={true}
              tickMargin={10}
              axisLine={true}
              height={70}
              interval={0}
              tick={CustomXAxisTick}
            />
            <YAxis
              label={{ value: "runs", angle: -90, position: "insideLeft" }}
              tickCount={3}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="frequency" fill="#007B7F" radius={3} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </>
  );
};
