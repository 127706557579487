import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../third-party/shadcn/card";
import { formatSeconds } from "../../../utils/number.util";
import { RunFrequency } from "../charts/v2/RunFrequency";
import { IntegrationPieChart } from "../charts/v2/IntegrationFailureRate";
import { RunTime } from "../charts/v2/RunTimes";
import { IntegrationItem } from "../../../models/integration-item.model";

export const MetricOverviewRow = ({
  count,
  averageTime,
  monthRuns,
}: {
  count: number;
  averageTime: number;
  monthRuns: IntegrationItem[];
}) => {
  return (
    <div className="flex flex-col gap-1.5">
      <div className="flex gap-1.5">
        <Card className="w-1/4 text-center">
          <CardHeader>
            <CardDescription>Active Integrations</CardDescription>
            <CardTitle className="text-4xl pt-3 mb-5">{count}</CardTitle>
          </CardHeader>
          <CardContent></CardContent>
        </Card>

        <Card className="w-1/4 text-center">
          <CardHeader>
            <CardDescription>Time to Execute</CardDescription>
            <CardTitle className="text-4xl pt-3 mb-5">
              {formatSeconds(averageTime)}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-xs text-muted-foreground">
              average this month
            </div>
          </CardContent>
        </Card>

        <Card className="w-1/4 text-center">
          <CardHeader>
            <CardDescription>Total Failures (month)</CardDescription>
            <CardTitle className="text-4xl pt-3 mb-5">
              {monthRuns.filter((x) => x.conclusion === "failure").length}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-xs text-muted-foreground">this month</div>
          </CardContent>
        </Card>

        <Card className="w-1/4 text-center">
          <CardHeader>
            <CardDescription>Total Runs</CardDescription>
            <CardTitle className="text-4xl pt-3 mb-5">
              {monthRuns.reduce((acc, cur) => acc + cur.attempt, 0)}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-xs text-muted-foreground">this month</div>
          </CardContent>
        </Card>
      </div>

    </div>
  );
};
