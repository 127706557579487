"use client";

import { Label, Pie, PieChart } from "recharts";

import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../third-party/shadcn/card";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../third-party/shadcn/chart";
import { IntegrationItem } from "../../../../models/integration-item.model";
import { useEffect, useState } from "react";

interface Props {
  data: IntegrationItem[];
}

function parseDay(dateString: string): number {
  const date = new Date(dateString);
  return date.getUTCDate();
}

function calculateAverage(arr: number[]): number {
  const sum = arr.reduce((a, b) => a + b, 0);
  return sum / arr.length;
}

function transformData(items: any[]): any[] {
  const groupedData: { [key: string]: { [key: number]: number[] } } = {};

  for (const item of items) {
    const day = parseDay(item.lastRunDate);

    if (!groupedData[item.friendlyName]) {
      groupedData[item.friendlyName] = { [day]: [item.duration] };
    } else if (!groupedData[item.friendlyName][day]) {
      groupedData[item.friendlyName][day] = [item.duration];
    } else {
      groupedData[item.friendlyName][day].push(item.duration);
    }
  }

  const result = [];
  const today = new Date().getUTCDate();

  for (const friendlyName in groupedData) {
    const data = [];

    for (let day = 1; day <= today; day++) {
      if (groupedData[friendlyName][day]) {
        const averageTime = calculateAverage(groupedData[friendlyName][day]);
        data.push(averageTime);
      } else {
        data.push(0);
      }
    }

    const colors = getColors();
    result.push({ label: friendlyName, data, ...colors });
  }

  return result;
}

function getColors() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  // Create color strings
  const borderColor = `rgb(${r}, ${g}, ${b})`;
  const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.5)`;

  return { borderColor, backgroundColor };
}

function totalRunTimePerIntegration(dailyRunTime) {
  return Math.round(
    dailyRunTime.reduce(function (a, b) {
      return a + b;
    }, 0) / 60
  );
}

const chartConfig = {} satisfies ChartConfig;

export const RunTimePieChart: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const [chartProps, setChartProps] = useState<
    { integrations: string; runTime: number; fill: string }[]
  >([]);

  const [totalRunTimeAllIntegrations, setTotalRunTimeAllIntegrations] =
    useState(0);

  const init = () => {
    if (!data?.length) {
      return;
    }
    const transformedData = transformData(data);

    const totalRunTime = Math.round(
      transformedData
        .map((item) => item.data)
        .flat()
        .reduce((acc, curr) => acc + curr, 0) / 60
    );
    setTotalRunTimeAllIntegrations(totalRunTime);
    const chartData = transformedData.map((item, index) => ({
      integrations: item.label,
      runTime: totalRunTimePerIntegration(item.data),
      fill: item.backgroundColor,
    }));
    setChartProps(chartData);
  };
  useEffect(() => {
    init();
  }, [data]);

  return !chartProps ? (
    <>Loading...</>
  ) : (
    <div className="h-[370px]">
      <CardHeader className="items-center pb-0">
        <CardTitle>Run Time - Total</CardTitle>
        <CardDescription className="text-center">
          Total run time (min) for each integration this month
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0 ">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[230px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Pie
              data={chartProps}
              dataKey="runTime"
              nameKey="integrations"
              innerRadius={55}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {totalRunTimeAllIntegrations}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground w-[50px]"
                        >
                          Total run time
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <PieChart accessibilityLayer />
          </PieChart>
        </ChartContainer>
        {chartProps.map((item, index) => (
          <div
            key={index}
            className="flex flex-row justify-between items-center py-[3px]"
          >
            <div className="flex flex-row gap-2">
              <div
                className="h-3 min-w-4"
                style={{ backgroundColor: item.fill }}
              ></div>

              <div className="text-[12px] font-normal leading-[100%] fill-muted text-gray-600">
                {item.integrations}
              </div>
            </div>

            <div className="text-[12px] font-medium text-gray-600">
              {item.runTime}
            </div>
          </div>
        ))}
      </CardContent>
    </div>
  );
};
