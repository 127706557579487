"use client";

import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/v2/BaseContainer";
import { Loading } from "../../components/shared/Loading";
import { IntegrationItem } from "../../models/integration-item.model";
import { integrationService } from "../../service/integration.service";
import { formatDate, getFirstDayOfMonth } from "../../utils/date.util";
import { Card } from "../../third-party/shadcn/card";
import { RootState, store } from "../../store";
import {
  setActiveIntegrations,
  setIntegrationItems,
} from "../../store/slice/Workflows/workflow.slice";
import { useSelector } from "react-redux";
import { ReloadIcon } from "@radix-ui/react-icons";
import { errorToast } from "../../constants/toast.constants";
import { useToast } from "@chakra-ui/react";
import { Calendar1Icon } from "lucide-react";
import { MetricOverviewRow } from "../../components/dashboard/card/MetricOverviewRow";
import { RunFrequency } from "../../components/dashboard/charts/v2/RunFrequency";
import { IntegrationPieChart } from "../../components/dashboard/charts/v2/IntegrationFailureRate";
import { RunTime } from "../../components/dashboard/charts/v2/RunTimes";
import { RunTimePieChart } from "../../components/dashboard/charts/v2/RunTimePieChart";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [monthRuns, setMonthRuns] = useState<IntegrationItem[]>([]);
  const [averageTime, setAverageTime] = useState(0);
  const [integrations, setIntegrations] = useState<IntegrationItem[]>([]);

  const toast = useToast();

  const integrationWorkflows = useSelector(
    (state: RootState) => state.workflows
  ) ?? { integrationItems: [], activeIntegrations: [] };

  const init = async () => {
    await setIntegrationData();
  };

  const setIntegrationData = async (force: boolean = false) => {
    let runs = integrationWorkflows.integrationItems;
    let activeIntegrations = integrationWorkflows.activeIntegrations;

    if (shouldLoadNewData() || force) {
      setLoading(true);

      try {
        runs = await integrationService.getWorkflowsWithRuns();
        activeIntegrations = Object.values(
          runs.reduce(
            (acc, current) => ({
              ...acc,
              [current.name]: acc[current.name] || current,
            }),
            {} as Record<string, IntegrationItem>
          )
        );

        store.dispatch(setIntegrationItems(runs));
        store.dispatch(setActiveIntegrations(activeIntegrations));
      } catch (error) {
        toast(errorToast("Failed to fetch integrations."));
      } finally {
        setLoading(false);
      }
    }

    const dateFiltered = runs.filter(
      (run) => new Date(run.lastRunDate) >= new Date(getFirstDayOfMonth())
    );

    const avgTime =
      dateFiltered.reduce((acc, cur) => acc + cur.duration, 0) /
      dateFiltered.length;

    setAverageTime(+avgTime.toFixed(2));
    setMonthRuns(dateFiltered);

    const { count } = await integrationService.getWorkflowCount();
    setCount(count);

    setIntegrations(activeIntegrations);
  };

  const shouldLoadNewData = () => {
    let runs = integrationWorkflows.integrationItems;
    let activeIntegrations = integrationWorkflows.activeIntegrations;

    if (!runs?.length || !activeIntegrations?.length) return true;

    const lastLoadDate = new Date(integrationWorkflows.lastLoadDate);
    const now = new Date();
    const diffInMs = now.getTime() - lastLoadDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return diffInDays >= 1 || lastLoadDate < new Date(getFirstDayOfMonth());
  };

  const lastRefreshedDate = integrationWorkflows.lastLoadDate;
  const formattedLastRefreshedDate = formatDate(lastRefreshedDate);

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <Loading
        loading={loading}
        text="Fetching all your data - this may take a few moments."
      />

      <BaseContainer className="flex-col flex-grow rounded-lg min-h-[calc(100vh-104px)] p-0 pb-[36px]">
        <div className="my-5">
          <div className="text-4xl font-bold">My Overview</div>
        </div>

        <div className="flex flex-col gap-1.5">
          <DashboardHeader
            formattedLastRefreshedDate={formattedLastRefreshedDate}
            setIntegrationData={setIntegrationData}
          />

          {/* The 4 cards top row */}
          <MetricOverviewRow
            count={count}
            averageTime={averageTime}
            monthRuns={monthRuns}
          />

          {/* Charts etc */}
          <div className="flex gap-1.5">
            <Card className="w-3/4 h-[50vh] max-h-[500px]">
              <RunFrequency data={monthRuns} />
            </Card>

            <Card className="w-1/4 h-[50vh] max-h-[500px]">
              <IntegrationPieChart data={monthRuns} />
            </Card>
          </div>

          <div className="flex gap-1.5 h-[60vh]">
            <Card className="w-3/4">
              <RunTime data={monthRuns} />
            </Card>

            {/* Replace this chart with a runfrequency one */}
            <Card className="w-1/4">
              <RunTimePieChart data={monthRuns} />
            </Card>
          </div>
        </div>

        {/* I am a dirty hoarder so I am keeping this in */}
        {/* <DashboardActiveIntegrations integrations={integrations} /> */}
      </BaseContainer>
    </Layout>
  );
};

const DashboardHeader = ({
  formattedLastRefreshedDate,
  setIntegrationData,
}: {
  formattedLastRefreshedDate: string;
  setIntegrationData: (force: boolean) => void;
}) => {
  return (
    <div className="flex flex-row items-center p-1.5 mb-1.5 justify-between">
      <div className="flex flex-row items-center gap-2">
        <Calendar1Icon />
        <div className=" text-md font-medium w-fit">
          01 {new Date().toLocaleString("default", { month: "long" })} - Today
        </div>
      </div>
      <div className="flex flex-row gap-3 items-center text-sm text-gray-500">
        <div className="flex flex-row gap-2">
          <p>Last refreshed:</p>
          <p>{formattedLastRefreshedDate}</p>
        </div>
        <Card
          className="w-fit h-8 p-2 flex justify-center items-center rounded-md cursor-pointer gap-2"
          onClick={() => setIntegrationData(true)}
        >
          <ReloadIcon />
          <p className="text-sm">Refresh</p>
        </Card>
      </div>
    </div>
  );
};
