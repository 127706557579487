import { InputGroup, Input, Flex, Text, propNames } from "@chakra-ui/react";
import { useState } from "react";

import { TooltipCustom } from "./TooltipCustom";

export const FormInput: React.FC<{
  name: string;
  title: string;
  defaultValue?: string | number;
  type?: string;
  placeholder?: string;
  leftAddon?: any;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  tooltipText?: string;
  maxLength?: number;
  textColor?: string;
  onChange?: (e: any) => void;
  [key: string]: any;
}> = (props: {
  name: string;
  title: string;
  defaultValue?: string | number;
  type?: string;
  placeholder?: string;
  leftAddon?: any;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  tooltipText?: string;
  textColor?: string;
  onChange?: (e: any) => void;
  [key: string]: any;
}) => {
  const { type, ...rest } = props;
  const [isInvalid, setIsInvalid] = useState(false);

  return (
    <Flex flexDirection={"column"} {...rest}>
      <Flex flexDir="row" fontWeight={500} mb="8px" fontSize={"14px"} pt={0}>
        <Text as={"span"} color={props.textColor}>
          {props.title}
        </Text>
        {props.required ? (
          <Text
            as={"span"}
            fontWeight="700"
            fontSize="14px"
            color="#792551"
            ml="3px"
          >
            *
          </Text>
        ) : (
          <></>
        )}
        {props.tooltipText ? (
          <TooltipCustom label={props.tooltipText} />
        ) : (
          <></>
        )}
      </Flex>
      <InputGroup>
        {props.leftAddon}
        <Input
          p={"8px 12px"}
          color={props.textColor}
          readOnly={props.readonly}
          disabled={props.disabled}
          required={props.required}
          value={props.defaultValue}
          name={props.name}
          placeholder={props.placeholder}
          // Add custom url validation
          type={props.type === "url" ? undefined : props.type ?? "text"}
          isInvalid={isInvalid}
          onChange={(e) => {
            if (props.onChange) props.onChange(e);
          }}
          maxLength={props.maxLength ?? props.type === "url" ? 2048 : 80}
        />
      </InputGroup>
      {isInvalid ? (
        <Text pt="6px" color="#B91C1C" fontSize="14px">
          {type === "password"
            ? "Password must be at least 15 characters"
            : "Invalid input"}
        </Text>
      ) : (
        <></>
      )}
    </Flex>
  );
};
